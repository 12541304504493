import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { IGetAgentRequest } from "@customer-formula/shared/services/customer/customer.input";
import { CustomerService } from "@customer-formula/shared/services/customer/customer.service";
import { take } from "rxjs/operators";

@Component({
    templateUrl: "customer-agent.component.html",
    styleUrls: ["./customer-agent.component.scss"],
    providers: [CustomerService],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CustomerAgentComponent {
  customerId: number;
  customerCode: string;

  agentName = "kundcenter";
  imageName = "";
  email = "";
  phone = "0480-45 11 45";
  form = "/Customer/cservice1.aspx";

  /**
   * Constructor
   *
   * @param customerService
   * @param activatedRoute
   */
  constructor(
    private customerService: CustomerService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.customerId = parseInt(
      this.activatedRoute.snapshot.queryParams.customerId,
    );
    this.customerCode = this.activatedRoute.snapshot.queryParams.customerCode;

    let host: string =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;

    host = host.replace("/Start.aspx", "");

    if (host.endsWith("/")) {
      host = host.slice(0, host.length - 1);
    }

    this.form = host + this.form;

    this.getCustomerAgent();
  }

  /**
   * Get customer agent for current customer
   */
  getCustomerAgent(): void {
    const request: IGetAgentRequest = {
      customerId: this.customerId,
      customerCode: this.customerCode,
    };
    this.customerService
      .getAgent(request)
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res.lastName) {
          this.agentName = res.lastName;
        }
        if (res.firstName) {
          this.agentName = `${res.firstName} ${res.lastName}`;
        }
        if (res.email && res.email !== "kundcenter@kalmarenergi.se") {
          this.email = res.email;
        }
        if (res.localNumber) {
          this.phone = res.localNumber;
        }

        this.imageName = this.getFilename(this.agentName);
      });
  }

  /**
   * Generate filename from agent name
   *
   * @param name
   * @returns filename
   */
  getFilename(name: string): string {
    return name.replace(/\s+/g, "-").trim().toLowerCase();
  }
}
